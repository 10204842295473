<template>
  <div class="home-content">
    <Title v-if="showtitle" :title="patentitle" :more="title" />
    <div class="patent-demo">
      <div class="patent-demo-header">
        <div
          class="pantent-demo-item"
          v-for="(item, index) in patentlist"
          :key="index + 'patentli'"
        >
          <div
            :style="`background: ${item.bg};color: ${item.color};`"
            class="header"
          >
            <img :src="imgList[index]" alt="" />
            <div class="header-title">{{ item.patentTitle }}</div>
            <div class="header-text">
              {{ item.patentText }}
            </div>
          </div>
          <div class="content">
            <div class="center">
              <p>{{ item.contentText1 }}</p>
              <p>{{ item.contentText2 }}</p>
              <p>{{ item.contentText3 }}</p>
            </div>
          </div>
          <div class="price">
            <p>
              <span class="text">申请价格：</span>
              <span class="icon">￥</span>
              <span class="num">{{ item.price }}</span>
            </p>
          </div>
          <div class="footer">
            <span style="cursor: pointer" @click="toPath(index)">查看详情</span>
          </div>
        </div>
      </div>
      <div class="footer">
        <span @click="servicePath" style="cursor: pointer">{{ more }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "./Title.vue";
export default {
  name: "PatentApplication",
  components: { Title },
  props: {
    patentlist: Array,
    more: String,
    patentitle: Array,
    title: String,
    showtitle: Boolean,
    type: String,
  },
  data() {
    return {
      imgList: [require('@/assets/service/lan.png'),require('@/assets/service/huang.png'),require('@/assets/service/hei.png')]
    };
  },

  mounted() {},

  methods: {
    servicePath() {
      let path = this.more === '更多专利服务' ? '/patentservice' : 'trademarkservice'
      this.$router.push(path)
    },
    toPath(index) {
      this.$router.push({
        path: "/details",
        query: {
          index: index,
          type: this.type,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.patent-demo {
  .patent-demo-header {
    display: flex;
    align-items: center;
    justify-content: center;

    .pantent-demo-item {
      width: 360px;
      height: 412px;
      margin: 30px;
      background: #ffffff;
      border: 1px solid #eeeeee;
      opacity: 1;
      transition: all 0.3s ease-out;
      border-radius: 2px;

      &:hover {
        box-shadow: 0px 4px 20px 6px rgb(8 5 30 / 6%);
        transform: translateY(-12px);
        border-color: transparent;
      }

      .header {
        height: 144px;
        position: relative;

        .header-title {
          padding: 24px 40px 10px;
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 36px;
        }
        img {
          right: 0;
          position: absolute;
        }
        .header-text {
          padding: 0 40px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 24px;
        }
      }

      .content {
        .center {
          padding: 20px 40px 0px 40px;

          p {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 32px;
            color: #333333;
          }
        }
      }

      .price {
        padding: 0 40px;

        .text {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }

        .icon {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #f13a3b;
        }

        .num {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #f13a3b;
        }
      }

      .footer {
        border-top: 1px solid #eeeeee;
        text-align: center;
        font-size: 16px;
        line-height: 46px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #f13a3b;
      }
    }
  }

  .footer {
    text-align: center;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 28px;
    color: #2680eb;
  }
}
.title_tow {
  display: flex;
  align-items: center;
  justify-content: center;
  .title_tow_item {
    margin: 0 40px;
    .text {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: #666666;
    }
    .anticon-check {
      color: #f13a3b;
    }
  }
}
</style>