<template>
  <div class="title-more">
    <div class="header">
      <h1 class="title">{{ more }}</h1>
    </div>
    <div class="title_tow">
      <div
        class="title_tow_item"
        v-for="(item, index) in title"
        :key="index + 'title'"
      >
        <a-icon type="check" />
        <span class="text">
          {{ item.title }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: {
    title: Array,
    more: String,
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.header {
  margin-top: 60px;
  .title {
    text-align: center;
    font-size: 32px;
    color: #333;
    font-weight: 900;
    line-height: 47px;
  }
}
.title_tow {
  display: flex;
  align-items: center;
  justify-content: center;
  .title_tow_item {
    margin: 0 40px;
    .text {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: #666666;
    }
    .anticon-check {
      color: #f13a3b;
    }
  }
}
</style>